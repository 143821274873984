<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select v-model="query.merCode" clearable placeholder="请选择商户">
        <el-option
          v-for="item in merOptions"
          :key="item.merCode"
          :label="item.merName"
          :value="item.merCode">
        </el-option>
    </el-select>
    <el-select v-model="query.productCode"  clearable placeholder="请选择产品">
      <el-option
        v-for="item in productOptions"
        :key="item.productCode"
        :label="item.productName"
        :value="item.productCode">
      </el-option>
    </el-select>
    <el-select v-model="query.channelCode"  clearable placeholder="请选择渠道名称">
      <el-option
        v-for="item in channelOptions"
        :key="item.channelCode"
        :label="item.channelName"
        :value="item.channelCode">
      </el-option>
    </el-select>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','PRODUCTCHANNEL_ALL','PRODUCTCHANNEL_ADD'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" :is-add="true" :productOptions="productOptions" :channelOptions="channelOptions"/>
    </div>

  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import { parseTime } from '@/utils/index'
import eForm from './form'
// 查询条件
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    },
    merOptions: {
      type: Array,
      required: true
    },
    productOptions: {
      type: Array,
      required: true
    },
    channelOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      downloadLoading: false
    }
  },
  methods: {
    checkPermission,
    // 去查询
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
