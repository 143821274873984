import request from '@/utils/request'

export function initData(url, params) {
  return request({
    url: url,
    method: 'get',
    params: params
  })
}

//商户管理 接口开始
export function addMerchantInfo(params) {
  return request({
    url: "/api/configure/merchant/addMerchantInfo",
    method: 'get',
    params: params
  })
}

export function queryMerchantInfoByParams(params) {
  return request({
    url: "/api/configure/merchant/queryMerchantInfoByParams",
    method: 'get',
    params: params
  })
}

export function updateMerchantInfo(params) {
  return request({
    url: "/api/configure/merchant/updateMerchantInfo",
    method: 'get',
    params: params
  })
}

export function queryMerchantInfoById(params) {
  return request({
    url: "/api/configure/merchant/queryMerchantInfoById",
    method: 'get',
    params
  })
}

export function queryAllMerchantInfos( params) {
  return request({
    url: "/api/configure/merchant/queryAllMerchantInfos",
    method: 'get',
    params
  })
}
//商户管理 接口结束

//产品管理 接口开始
export function addProductInfo(params) {
  return request({
    url: "/api/configure/products/addProductInfo",
    method: 'get',
    params:params
  })
}

export function queryProductInfoByParams(params) {
  return request({
    url: "/api/configure/products/queryProductInfoByParams",
    method: 'get',
    params:params
  })
}

export function updateProductInfo(params) {
  return request({
    url: "/api/configure/products/updateProductInfo",
    method: 'get',
    params:params
  })
}

export function queryProductInfoById(params) {
  return request({
    url: "/api/configure/products/queryProductInfoById",
    method: 'get',
    params:params
  })
}

export function queryAllProductInfos(params) {
  return request({
    url: "/api/configure/products/queryAllProductInfos",
    method: 'get',
    params:params
  })
}
//产品管理 结束

//支付渠道管理 开始
export function addChannelInfo(params) {
  return request({
    url: "/api/configure/channel/addChannelInfo",
    method: 'get',
    params:params
  })
}

export function queryChannelInfoByParams(params) {
  return request({
    url: "/api/configure/channel/queryChannelInfoByParams",
    method: 'get',
    params:params
  })
}

export function updateChannelInfo(params) {
  return request({
    url:  "/api/configure/channel/updateChannelInfo",
    method: 'get',
    params:params
  })
}

export function queryChannelInfoById(params) {
  return request({
    url:  "/api/configure/channel/queryChannelInfoById",
    method: 'get',
    params:params
  })
}

export function queryAllChannelInfos(params) {
  return request({
    url:  "/api/configure/channel/queryAllChannelInfos",
    method: 'get',
    params:params
  })
}

export function queryParams(params) {
  return request({
    url: "/api/configure/params/queryParams",
    method: 'get',
    params
  })
}

export function doQueryAccountBalance(params) {
  return request({
    url: "/api/pay/doQueryAccountBalance",
    method: 'get',
    params
  })
}
//支付渠道管理 结束

//订单管理  开始
export function paymentProcessBySerialNo(params) {
  return request({
    url: "/api/configure/order/PaymentProcessBySerialNo",
    method: 'get',
    params
  })
}

export function queryOrderInfoByParams(params) {
  return request({
    url: "/api/configure/order/queryOrderInfoByParams",
    method: 'get',
    params: params
  })
}

export function queryOrderInfoById(params) {
  return request({
    url: "/api/configure/order/queryOrderInfoById",
    method: 'get',
    params
  })
}

//订单管理  结束

// 产品支付渠道管理 开始
export function addRelation(params) {
  return request({
    url:  "/api/configure/channel/addRelation",
    method: 'get',
    params
  })
}

export function queryChannelRelationInfoByParams(params) {
  return request({
    url:  "/api/configure/channel/queryChannelRelationInfoByParams",
    method: 'get',
    params
  })
}

export function relieveRelation(params) {
  return request({
    url:  "/api/configure/channel/relieveRelation",
    method: 'get',
    params
  })
}

export function queryChannelRelationInfoById(params) {
  return request({
    url:  "/api/configure/channel/queryChannelRelationInfoById",
    method: 'get',
    params
  })
}

export function deleteRelation(params) {
  return request({
    url:  "/api/configure/channel/deleteRelation",
    method: 'get',
    params
  })
}

