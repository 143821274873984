<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增产品渠道配置' : '编辑产品渠道配置'" append-to-body width="700px">
    <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="产品名称" prop="productCode">
        <el-select v-model="form.productCode" placeholder="请选择产品">
        <el-option
          v-for="item in productOptions"
          :key="item.productCode"
          :label="item.productName"
          :value="item.productCode">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="渠道名称" prop="channelId">
       <el-select v-model="form.channelId" placeholder="请选择渠道">
        <el-option
          v-for="item in channelOptions"
          :key="item.id"
          :label="item.channelName"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addRelation, relieveRelation } from '@/api/dataPms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    productOptions: {
      type: Array,
      required: true
    },
    channelOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false, loading: false, form: {id: '',productId: '',productCode: '', channelId: ''}, 
      rules: {
        
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.setFormProductId()
      this.formData = { productChannelRelationInfo: this.form }
      this.params = {'content':JSON.stringify(this.formData)}
      addRelation(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.setFormProductId()
      this.formData = { productChannelRelationInfo: this.form }
      this.params = {'content':JSON.stringify(this.formData)}
      relieveRelation(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    setFormProductId(){
      for (var i = this.productOptions.length - 1; i >= 0; i--) {
        if(this.productOptions[i].productCode==this.form.productCode) {
          this.form.productId=this.productOptions[i].id
        }
      }
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form =  {id: '',productCode: '', channelId: '',productId:''}
    }
  }
}
</script>

<style scoped>

</style>
